export default class ModalDiretoresController {
    constructor() {
        this.directorsContainer = document.getElementById("diretoresView");

        this.directorsContainer.addEventListener('click', this.directorModal.bind(this));
    }

    directorModal(e) {
        if (e.target.classList.contains('singleDiretor')) {
            const data = e.target.parentElement.dataset;
            const view = document.getElementById('modal');
            this._toggleModal();

            view.innerHTML = /*html*/`
                <div class="interno-diretores  animated faster zoomIn">
                    <img src="${data.foto}" src="${data.nome}">
                    <h2>${data.nome}</h2>
                    <h3>${data.cargo}</h3>
                    <p class="diretores-interno-citacao">"${data.citacao }"</p>
                    <div class="diretores-bio">${data.bio}</div>
                    <ul class="diretores-social">
                        ${data.vimeo ? `
                            <li class="menu-social-li"><a target="_blank" class="menu-link menu-social first-social" title="Siga no Vimeo" href="${data.vimeo}"><i class="fab fa-vimeo"></i></a></li> 
                        ` : ''
                        }
                        ${data.facebook ? `
                            <li class="menu-social-li"><a target="_blank" class="menu-link menu-social" title="Curta no Facebook" href="${data.facebook}"><i class="fab fa-facebook-square"></i></a></li>
                        ` : ''}
                        ${data.twitter ? `
                            <li class="menu-social-li"><a target="_blank" class="menu-link menu-social" title="Siga no Twitter" href="${data.twitter}"><i class="fab fa-twitter-square"></i></a></li>
                        ` : ''}
                        ${data.linkedin ? `
                            <li class="menu-social-li"><a target="_blank" class="menu-link menu-social" title="Siga no Linkedin" href="${data.linkedin}"><i class="fab fa-linkedin"></i></a></li>
                        ` : ''}
                        ${data.insta ? `
                            <li class="menu-social-li"><a target="_blank" class="menu-link menu-social" title="Siga no Instagram" href="${data.insta}"><i class="fab fa-instagram"></i></a></li>
                        ` : ''}
                    </ul>
                </div>
            `
        }
    }

    _toggleModal() {
        const body = document.querySelector("body");
        if (body.classList.contains("modal-ative")) {
            body.classList.remove("modal-ative");
        } else {
            body.classList.add("modal-ative");
        }
    }

}