import FrasesController from "./FrasesController";
import DiretoresController from "./DiretoresController";
import ContatoController from "./ContatoController";

export default class TraducaoController {
  constructor() {
    this.texts = {
      "pt-BR": {
        SOBRE: "Sobre",
        TRABALHOS: "Trabalhos",
        CLIENTES: "Clientes",
        DIRETORES: "Diretores",
        CONTATO: "Contato",
        SHOWALL: "Mostrar Tudo",
        BRANDED: "Branded Content",
        INSTITUCIONAL: "Institucional",
        PUBLICIDADE: "Publicidade",
        FASHION: "Fashion",
        EXPERIMENTAL: "Experimental",
        TITULO_TRABALHO: "Trabalhos",
        FICHA: "Ficha Técnica",
        CLIENTE: "Cliente",
        AGENCIA: "Agência",
        CRIACAO: "Criação",
        PRODUCAO: "Produção",
        DIRECAO: "Direção",
        DIRECAO_F: "Direção de Fotografia",
        DIRECAO_A: "Direção de Arte",
        EDICAO: "Edição",
        FINALIZACAO: "Finalização",
        COR: "Cor",
        OP_CAMERA: "Operador de camera",
        TRILHA: "Trilha",
        LOCUCAO: "Locução",
        Assistente: "Assistente",
        FIGURINO: "Figurino",
        SOM_DIRETO: "Som direto",
        MAQUIAGEM: "Maquiagem",
        AGRADECIMENTO: "Agradecimentos",
        BTN_VEJA_MAIS: "↓ Veja Mais",
        TITULO_CLIENTES: "Clientes",
        TITULO_DIRETORES: "Diretores",
        F_NOME: "Seu nome (Obrigatório)",
        F_EMAIL: "Seu e-mail (Obrigatório)",
        F_SUBJECT: "Assunto",
        F_MSG: "Sua mensagem",
        F_BUTTOM: "Enviar",
        F_SUCESS: "Mensagem enviada com sucesso",
        F_WARNNG: "Erro ao enviar mensagem"
      },
      en: {
        SOBRE: "About",
        TRABALHOS: "Jobs",
        CLIENTES: "Clients",
        DIRETORES: "Directors",
        CONTATO: "Contact",
        SHOWALL: "Show All",
        BRANDED: "Branded Content",
        INSTITUCIONAL: "Institutional",
        PUBLICIDADE: "Advertising",
        FASHION: "Fashion",
        EXPERIMENTAL: "Experimental",
        TITULO_TRABALHO: "Jobs",
        FICHA: "Datasheet",
        CLIENTE: "Client",
        AGENCIA: "Agency",
        CRIACAO: "Creation",
        PRODUCAO: "Producer",
        DIRECAO: "Director",
        DIRECAO_F: "DOP",
        DIRECAO_A: "Art Direction",
        EDICAO: "Editor",
        FINALIZACAO: "Post Production",
        COR: "Color",
        OP_CAMERA: "Camera Operator",
        TRILHA: "SoundTrack Producer",
        LOCUCAO: "Speaker",
        Assistente: "Assistent",
        FIGURINO: "Costume Designer",
        SOM_DIRETO: "Direct Sound",
        MAQUIAGEM: "Mua",
        AGRADECIMENTO: "Special Thanks",
        BTN_VEJA_MAIS: "↓ See More",
        TITULO_CLIENTES: "Clients",
        TITULO_DIRETORES: "Directors",
        F_NOME: "Your Name (required)",
        F_EMAIL: "Your Email (required)",
        F_SUBJECT: "Subject",
        F_MSG: "Your Message",
        F_BUTTOM: "Send",
        F_SUCESS: "Message sent successfully",
        F_WARNNG: "Error sending message"
      }
    };

    // muda a linguagem de acorodo com a preferência do usuário
    this.btnLang = document.querySelector("#change-language");
    this.btnLang.addEventListener("click", () => {
      this.setLang(this.btnLang.dataset.lang);
      this.updateCitacoes();
    });

    // start a ultima opção de lingua du usuário
    if (localStorage.getItem("flag"))
      this.setLang(localStorage.getItem("flag"));
  }

  /**
   * Function to set a new langue to the site
   * @param lang
   */
  setLang(lang) {
    const flag = lang;
    const keys = document.querySelectorAll("[data-key]");

    this.setFlag(flag);

    keys.forEach(key => {
      const data = key.dataset.key;
      key.innerText = this.texts[flag][data];
    });
  }

  updateCitacoes() {
    const frases = new FrasesController();
    const diretores = new DiretoresController();
    const contato = new ContatoController();
    frases.getCitataos();
    frases.getFraseTrabalhos();
    frases.getFraseClientes();
    diretores.getDiretores();
    contato.getContato();
  }

  /**
   * Helper funcion to change the flag and the lang
   * @param flag
   * @private
   */
  setFlag(flag) {
    if (flag === "en") {
      this.btnLang.dataset.lang = "pt-BR";
      this.btnLang.style.backgroundImage =
        "url('./wp-content/themes/abajour/dist/images/brazil.png')";
      localStorage.setItem("flag", flag);
    } else {
      this.btnLang.dataset.lang = "en";
      this.btnLang.style.backgroundImage =
        "url('./wp-content/themes/abajour/dist/images/us.png')";
      localStorage.setItem("flag", flag);
    }
  }
}
