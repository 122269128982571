import OptionModel from "../models/OptionsModel";

export default class SlideTopoController {
  constructor() {
    this.request = new OptionModel();
    this.request.getVideo(this.video);
    this.request.getBgs(this.outros_bgs);
    setInterval(() => {
          this.request.getBgs(this.outros_bgs)
      }, 10000);
  }

  video(objeto) {
    const videoWrap = document.getElementById("video_wrap");
    const fotos = document.getElementById("wrap_frases");
    fotos.classList.remove('wrap_sections');
    fotos.classList.remove('wrap-device-width');
    fotos.style.background = 'none';

    const url = objeto.split("/");
    videoWrap.innerHTML = /*html*/ `
      <style>
        .vimeo-wrapper {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          height: 100vh; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
          max-height: 400px;
          z-index: -1;
          pointer-events: none;
          overflow: hidden;
        }

        @media all and (min-width: 720px) {
          .vimeo-wrapper {
            max-height: 900px;
          }
        }



        .vimeo-wrapper iframe {
          width: 100vw;
          height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
          min-height: 100vh;
          max-height: 1080px;
          min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
          position: absolute;
          top: 0;
          left: 0;
        }

      </style>
      <div class="vimeo-wrapper">

        <iframe src="https://player.vimeo.com/video/${
          url[3]
        }?background=1&autoplay=1&autopause=0&loop=1&byline=0&title=0"
           frameborder="0"></iframe>
      </div>
    `;
  }

  fotos(objeto) {
    const topo = document.getElementById("wrap_frases");
    const randomImg =
      objeto[Math.floor(Math.random() * objeto.length)].url_imagem;
    topo.style.backgroundImage = `url('${randomImg}')`;
    topo.style.backgroundSize = "cover";
  }

  outros_bgs(objeto) {
    const topos = document.querySelectorAll(".wrap_sections");
    topos.forEach(container => {
      const randomImg = objeto[Math.floor(Math.random() * objeto.length)].url_imagem;
      container.style.backgroundImage = `url('${randomImg}')`;
      container.style.backgroundSize = "cover";
    })
  }
}
