import DiretoresView from "../views/DiretoresView";
import OptionsModel from "../models/OptionsModel";

export default class diretoresController {
    constructor() {
        this.getDiretores();
    }

    getDiretores() {
        const request = new OptionsModel();
        const diretoresView = new DiretoresView(document.getElementById('diretoresView'));
        request.getDiretores(diretoresView);
    }
}