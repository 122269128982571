import ModalModel from "../models/ModalModel";
import ModalView from "../views/ModalView";

export default class Modal {
  constructor() {
    this.jobsContainer = document.getElementById("jobs");
    this.modalWrap = document.getElementById("wrap_modal");

    this.closeModal = document.getElementById("close_modal");
    this.myModal = new ModalView(document.getElementById("modal"));

    this.modalWrap.addEventListener("click", e => {
      if (e.target.id === "btn_open_infos") {
        this._toggleInfos(e);
      }
    });

    this.jobsContainer.addEventListener("click", e => {
      if(e.target.dataset.id) {
        this.modal(e.target.dataset.id);
        this._toggleModal();
      }
    });

    this.closeModal.addEventListener("click", e => {
      this._toggleModal();
    });
  }
  modal(id) {
    const data = new ModalModel(id);

    data.getModal(this.myModal);
  }

  _toggleModal() {
    const body = document.querySelector("body");
    if (body.classList.contains("modal-ative")) {
      body.classList.remove("modal-ative");
      this.myModal.limpa();
    } else {
      body.classList.add("modal-ative");
    }
  }
  _toggleInfos(e) {
    const lang = localStorage.getItem("flag");

    const btn_target = e.target;
    const infos_container = this.modalWrap;

    if (infos_container.classList.contains("infos_open")) {
      infos_container.classList.remove("infos_open");

      if (lang === "pt-BR") {
        btn_target.innerText = "↑ Créditos";
      } else {
        btn_target.innerText = "↑ Credits";
      }
    } else {
      infos_container.classList.add("infos_open");

      if (lang === "pt-BR") {
        btn_target.innerText = "↓ Créditos";
      } else {
        btn_target.innerText = "↓ Credits";
      }
    }
  }
}
