import View from "./View.js";

export default class WorksViews extends View {
  _template(model, key, page) {
    const jobsWrap = document.getElementById("btn_load_more");

    if(model.data.length < 15) {
      jobsWrap.style.display = 'none';
    } else {
      jobsWrap.dataset.categ = key;
      jobsWrap.dataset.page = page;
      jobsWrap.style.display = 'block';
    }

    return model.data.map(jobs => {
        return /*html*/`
          <div class="col col-3">
            <div class="job-container animated faster zoomIn" data-id="${jobs.id}">
                <div class="infos-hover" data-id="${jobs.id}">
                    <h2 data-id="${jobs.id}">${jobs.title.rendered}</h2>
                </div>
                <img data-id="${jobs.id}" src="${jobs.acf.capa}" alt="${jobs.title.rendered}">
            </div>
          </div>
        `
      })
      .join("");
  }

  update(model, chave, page, isPage) {
    page++;
    if(!isPage) {
      this._elemento.innerHTML = this._template(model, chave, page);
    } else {
      this._elemento.insertAdjacentHTML('beforeend', this._template(model, chave, page));
    }
  }
}
