import View from './View';

export default class CitacoesView extends View {
    _template(model) {
        const lang = localStorage.getItem('flag') ? localStorage.getItem('flag') : 'pt-BR';
        return `
            <img class='logo-contato' src="${model.logo}" alt="Abjour Filmes">
            <p>${lang === 'en' ? model.text_en : model.text}</p>
            <p class="contact-mail"><a href="mailto:${model.email}">${model.email}</a></p>
            <p>${lang === 'en' ? model.frase_final_en : model.frase_final}</p>
        `;
    }
}
