import axios from "axios";

export default class OptionsModel {
  constructor() {
    this.url = `/wp-json/acf/v3/options/options`;
    this.options = this._getOptions(); // load data from REST API
  }

  getFrases(frase_pt, frase_en, view, verificador) {
    this.options.then(response => {
      if (verificador) {
        if (response.data.acf[verificador]) {
          const frases = {
            pt: response.data.acf[frase_pt],
            en: response.data.acf[frase_en]
          };
          view.update(frases);
        }
      } else {
        const frases = {
          pt: response.data.acf[frase_pt],
          en: response.data.acf[frase_en]
        };
        view.update(frases);
      }
    });
  }

  getVideo(funcVideos) {
    this.options.then(response => {
      if (window.innerWidth > 1170 && response.data.acf.video) {
        const objeto = response.data.acf.link_do_video;
        funcVideos(objeto);
      }
    });
  }

  getBgs(outros_bgs) {
    this.options.then(response => {
      const objeto = response.data.acf.imagens_de_fundo;
      outros_bgs(objeto);
    })
  }

  getClientes(view) {
    this.options.then(response => {
      const objeto = response.data.acf.logos;
      view.update(objeto);
    })
  }

  getDiretores(view) {
    this.options.then(response => {
      view.update(response.data.acf.cadastrar_diretor);
    })
  }

  getContato(view) {
    this.options.then(response => {

      const data = {
        logo: response.data.acf.logo_contato,
        email: response.data.acf.email_de_contato,
        text_en: response.data.acf.texto_area_contato_en,
        text: response.data.acf.texto_area_contato,
        frase_final: response.data.acf.frase_final,
        frase_final_en: response.data.acf.frase_final_en
      };
      view.update(data);
    })
  }

  getSobre(view) {
    this.options.then(response => {
        view.update(response.data.acf.sobre);
    })
  }

  // get the initial response from options page
  _getOptions() {
    return axios.get(this.url);
  }
}
