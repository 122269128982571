import CitacoesView from "../views/CitacoesView";
import OptionModel from "../models/OptionsModel";

export default class FrasesController {
  constructor() {
    this.request = new OptionModel();
    this.getCitataos();
    this.getFraseTrabalhos();
    this.getFraseClientes();
  }

  getCitataos() {
    const citacoes = new CitacoesView(document.getElementById("frases"));
    this.request.getFrases("frase", "frase_en", citacoes, "frase_no_cabecalho");
  }

  getFraseTrabalhos() {
    const fraseTrabalhos = new CitacoesView(
      document.getElementById("frase_trabalhos")
    );
    this.request.getFrases(
      "frase_trabalhos",
      "frase_trabalhos_en",
      fraseTrabalhos
    );
  }

  getFraseClientes() {
    const frasesClientes = new CitacoesView(document.getElementById("frase_clientes"));
    this.request.getFrases(
      "frase_clientes",
      "frase_clientes_en",
      frasesClientes
    );
  }

  _getFrases(frase_pt, frase_en, view) {
    this.options.then(response => {
      const frases = {
        pt: response.data.acf[frase_pt],
        en: response.data.acf[frase_en]
      };
      view.update(frases);
    });
  }
}
