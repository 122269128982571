import View from "./View";

export default class SobreView extends View {
    _template(model) {

        const lang = localStorage.getItem("flag") ? localStorage.getItem("flag") : 'pt-BR';

        return `
            <div class="sobre-container animated faster zoomIn">
                <div class="sobre-texto">
                    ${lang === 'en' ? model.texto_en : model.texto}        
                </div>
            </div>
        `;
    }
}