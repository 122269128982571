import View from "./View";

export default class ModalView extends View {
  _template(model) {
    const lang = localStorage.getItem("flag") ? localStorage.getItem("flag") : 'pt-BR';

    const texts = {
      "pt-BR": {
        FICHA: "↑ Créditos",
        TITULO: "Título",
        CLIENTE: "Cliente",
        AGENCIA: "Agência",
        CRIACAO: "Criação",
        PRODUCAO: "Produção",
        DIRECAO: "Direção",
        DIRECAO_F: "Direção de Fotografia",
        DIRECAO_A: "Direção de Arte",
        EDICAO: "Edição",
        FINALIZACAO: "Finalização",
        COR: "Cor",
        OP_CAMERA: "Operador de camera",
        TRILHA: "Trilha",
        LOCUCAO: "Locução",
        Assistente: "Assistente",
        FIGURINO: "Figurino",
        SOM_DIRETO: "Som direto",
        MAQUIAGEM: "Maquiagem",
        AGRADECIMENTO: "Agradecimentos"
      },
      en: {
        FICHA: "↑ Credits",
        TITULO: "Title",
        CLIENTE: "Client",
        AGENCIA: "Agency",
        CRIACAO: "Creation",
        PRODUCAO: "Producer",
        DIRECAO: "Director",
        DIRECAO_F: "DOP",
        DIRECAO_A: "Art Direction",
        EDICAO: "Editor",
        FINALIZACAO: "Post Production",
        COR: "Color",
        OP_CAMERA: "Camera Operator",
        TRILHA: "SoundTrack Producer",
        LOCUCAO: "Speaker",
        Assistente: "Assistent",
        FIGURINO: "Costume Designer",
        SOM_DIRETO: "Direct Sound",
        MAQUIAGEM: "Mua",
        AGRADECIMENTO: "Special Thanks"
      }
    };

    const url = model.acf.video.split("/");

    return /*html*/ `
      <style>
        .vimeo-wrapperModal {
          position: relative;
          width: 100vw;
          height: 100vh;
          overflow: hidden;

        }

        @media all and (min-width: 720px) {

        }

        .vimeo-wrapperModal iframe {
          width: 100%;
          height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
          margin-top: 60px;
          padding: 10px;
          z-index: 997;
        }

         @media all and (min-width: 720px) {
            .vimeo-wrapperModal iframe {
              width: 100%;
              height: 100%; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
              position: absolute;
              margin-top: 0;
              top: 0;
              left: 0;
            }
        }

      </style>

      <div class="vimeo-wrapperModal">
        <iframe src="https://player.vimeo.com/video/${url[3]}" frameborder="0"></iframe>
      </div>

      <span id='btn_open_infos'>${texts[lang]["FICHA"]}</span>
      <div id="infos-work">
        <div class="infos-content"><span class="infos-title">${texts[lang]["TITULO"]}</span>: ${(lang === 'pt-BR') ? model.title.rendered : model.acf.title_in_english}</div>
        ${model.acf.cliente ? `<div class="infos-content"><span class="infos-title">${texts[lang]["CLIENTE"]}</span>: ${model.acf.cliente}</div>` : ''}
        ${model.acf.agencia ? `<div class="infos-content"><span class="infos-title">${texts[lang]["AGENCIA"]}</span>: ${model.acf.agencia}</div>` : ''}
        ${model.acf.criacao ? `<div class="infos-content"><span class="infos-title">${texts[lang]["CRIACAO"]}</span>: ${model.acf.criacao}</div>`: ''}
        ${model.acf.producao ? `<div class="infos-content"><span class="infos-title">${texts[lang]["PRODUCAO"]}</span>: ${model.acf.producao}</div>`: ''}
        ${model.acf.diretor ? `<div class="infos-content"><span class="infos-title">${texts[lang]["DIRECAO"]}</span>: ${model.acf.diretor}</div>`: ''}
        ${model.acf.diretor_fotografia ? `<div class="infos-content"><span class="infos-title">${texts[lang]["DIRECAO_F"]}</span>: ${model.acf.diretor_fotografia}</div>`: ''}
        ${model.acf.direcao_de_arte ? `<div class="infos-content"><span class="infos-title">${texts[lang]["DIRECAO_A"]}</span>: ${model.acf.direcao_de_arte}</div>`: ''}
        ${model.acf.edicao ? `<div class="infos-content"><span class="infos-title">${texts[lang]["EDICAO"]}</span>: ${model.acf.edicao}</div>`: ''}
        ${model.acf.finalizacao ? `<div class="infos-content"><span class="infos-title">${texts[lang]["FINALIZACAO"]}</span>: ${model.acf.finalizacao}</div>`: ''}
        ${model.acf.cor ? `<div class="infos-content"><span class="infos-title">${texts[lang]["COR"]}</span>: ${model.acf.cor}</div>`: ''}
        ${model.acf.operador_de_camera ? `<div class="infos-content"><span class="infos-title">${texts[lang]["OP_CAMERA"]}</span>: ${model.acf.operador_de_camera}</div>`: ''}
        ${model.acf.trilha ? `<div class="infos-content"><span class="infos-title">${texts[lang]["TRILHA"]}</span>: ${model.acf.trilha}</div>`: ''}
        ${model.acf.locucao ? `<div class="infos-content"><span class="infos-title">${texts[lang]["LOCUCAO"]}</span>: ${model.acf.locucao}</div>`: ''}
        ${model.acf.assistente ? `<div class="infos-content"><span class="infos-title">${texts[lang]["Assistente"]}</span>: ${model.acf.assistente}</div>`: ''}
        ${model.acf.figurino ? `<div class="infos-content"><span class="infos-title">${texts[lang]["FIGURINO"]}</span>: ${model.acf.figurino}</div>`: ''}
        ${model.acf.som_direto ? `<div class="infos-content"><span class="infos-title">${texts[lang]["SOM_DIRETO"]}</span>: ${model.acf.som_direto}</div>`: ''}
        ${model.acf.maquiagem ? `<div class="infos-content"><span class="infos-title">${texts[lang]["MAQUIAGEM"]}</span>: ${model.acf.maquiagem}</div>`: ''}
        ${model.acf.agradecimento ? `<div class="infos-content"><span class="infos-title">${texts[lang]["AGRADECIMENTO"]}</span>: ${model.acf.agradecimento}</div>`: ''}
      </div>
    `;
  }

  limpa() {
    this._elemento.innerHTML = "<div class=\"lds-ellipsis\"><div></div><div></div><div></div><div></div></div>";
  }
}
