import axios from "axios";

export default class WorksModel {
  constructor(id) {
    this.url = `/wp-json/wp/v2/trabalhos/${id}`;
    this.options = this._getData(); // load data from REST API
  }

  getModal(view) {
    this.options.then(res => {
      view.update(res.data);
    });
  }

  // get the initial response from options page
  _getData() {
    return axios.get(this.url);
  }
}
