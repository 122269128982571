import View from "./View.js";

export default class ClientesViews extends View {
    _template(model) {
        return model.map(cliente => {
            return /*html*/`
                <style>
                    .clientes-logos {
                        display: block;
                        width: 100%;
                        height: 100px;
                        margin-bottom: 10px;
                        background: #fff;
                        object-fit: contain;
                        padding: 20px;
                    }
                </style>
                <div class='col col-5'>
                    <a href="${cliente.link}" target="_blank">
                        <img src="${cliente.logo}" alt="Clientes" class='clientes-logos'>
                    </a>
                </div>
           `
        }).join("");
    }
}