import View from "./View.js";

export default class DiretoresViews extends View {
    _template(model) {
        const lang = localStorage.getItem('flag');
        return model.map(diretor => {
            return /*html*/ `
                <div class='col col-3'
                    data-nome="${diretor.nome}"
                    data-foto="${diretor.foto}"
                    data-cargo="${lang === 'en' ? diretor.cargo_en : diretor.cargo}"
                    data-citacao="${lang === 'en' ? diretor.citacao_en : diretor.citacao }"
                    data-bio="${lang === 'en' ? diretor.biografia_en : diretor.biografia }"
                    data-vimeo="${diretor.vimeo}"
                    data-linkedin="${diretor.linkedin}"
                    data-twitter="${diretor.twitter}"
                    data-insta="${diretor.instagram}"
                    data-facebook="${diretor.facebook}"

                >
                    <img  class='diretores-foto singleDiretor' src="${diretor.foto}" src="${diretor.nome}">
                    <h2 class='diretores-title singleDiretor'>${diretor.nome}</h2>
                    <h3 class='diretor-cargo'>${lang === 'en' ? diretor.cargo_en : diretor.cargo}</h3>
                    <p class="diretor-citacao">"${lang === 'en' ? diretor.citacao_en : diretor.citacao }"</p>
                </div>
            `;
        }).join("");
    }
}