import OptionModel from "../models/OptionsModel";
import ContatoView from '../views/ContatoView';

export default class ContatoController {
    constructor() {
        this.getContato();
    }

    getContato() {
        const request = new OptionModel();
        const contatoView = new ContatoView(document.getElementById('dados_contato'));
        request.getContato(contatoView);
    }
}