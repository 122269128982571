import axios from "axios";

export default class WorksModel {
  constructor() {
    this.url = "/wp-json/wp/v2/trabalhos?per_page=15";
    this.options = this._getData(); // load data from REST API
  }

  getWorks(key, page, view) {
    let chave;
    let isPage;

    if (key === "FASHION") {
      chave = 2;
      this.url = `/wp-json/wp/v2/trabalhos?per_page=15&page=${page}&categories=${chave}`;
      this.options = this._getData(); // load data from REST API
    } else if (key === "BRANDED") {
      chave = 1;
      this.url = `/wp-json/wp/v2/trabalhos?per_page=15&page=${page}&categories=${chave}`;
      this.options = this._getData(); // load data from REST API
    } else if (key === "INSTITUCIONAL") {
      chave = 3;
      this.url = `/wp-json/wp/v2/trabalhos?per_page=15&page=${page}&categories=${chave}`;
      this.options = this._getData(); // load data from REST API
    } else if (key === "PUBLICIDADE") {
      chave = 4;
      this.url = `/wp-json/wp/v2/trabalhos?per_page=15&page=${page}&categories=${chave}`;
      this.options = this._getData(); // load data from REST API

    } else if (key === "EXPERIMENTAL") {
        chave = 5;
        this.url = `/wp-json/wp/v2/trabalhos?per_page=15&page=${page}&categories=${chave}`;
        this.options = this._getData(); // load data from REST API
    } else {
      this.url = `/wp-json/wp/v2/trabalhos?per_page=15&page=${page}`;
      this.options = this._getData(); // load data from REST API
    }

    if (page > 1) {
      isPage = true;
    }

    this.options.then(response => {
      view.update(response, key, page, isPage);
    });
  }

  // get the initial response from options page
  _getData() {
    return axios.get(this.url);
  }
}
