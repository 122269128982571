import WorksModel from "../models/WorksModel";
import worksView from "../views/WorksView";

export default class WorksController {
  constructor() {
    this.menu = document.querySelectorAll(".work-menu");
    this.menuCheckBox = document.getElementById("work-menu-cb");

    this.menuCheckBox.addEventListener("change", this.getWorks);
    this.menu.forEach(workMenu => {
      workMenu.addEventListener("click", this.getWorks);
    });

    //pagination
    this.jobsWrap = document.getElementById("btn_load_more");
    this.jobsWrap.addEventListener("click", btn => {
      this.getWorks(btn.target.dataset.page, btn.target.dataset.categ);
    });

    this.getWorks(1, 'SHOWALL');
  }

  getWorks(page, keyCateg) {
    let key = keyCateg ? keyCateg : "SHOWALL";
    let pagination = page;

    if (isNaN(pagination)) {
      pagination = 1;
    }

    if (!this.menu) {
      if (!this.selectedOptions) {
        const menu = document.querySelectorAll(".work-menu");
        menu.forEach(e => e.classList.remove("work-checked"));
        this.classList.add("work-checked");
      }

      key = this.dataset.key
        ? this.dataset.key
        : this.selectedOptions[0].dataset.key;
    }

    const request = new WorksModel();
    const trabalhos = new worksView(document.getElementById("jobs"));

    request.getWorks(key, pagination, trabalhos);
  }
}
