import "../sass/style.scss"; // Sass

// controllers
import smoothscroll from 'smoothscroll-polyfill';
import TraducaoController from "./controllers/TraducaoController";
import MenuController from "./controllers/MenuController";
import FrasesController from "./controllers/FrasesController";
import SlideTopoController from "./controllers/SlideTopoController";
import WorksController from "./controllers/WorksController";
import ModalController from "./controllers/ModalController";
import ClientesController from './controllers/ClientesController';
import DiretoresController from './controllers/DiretoresController';
import ModalDiretoresController from './controllers/ModalDiretoresController';
import ContatoController from './controllers/ContatoController';

// vars
smoothscroll.polyfill();
const traducao = new TraducaoController();
const menu = new MenuController();
const frases = new FrasesController();
const slide = new SlideTopoController();
const works = new WorksController();
const modal = new ModalController();
const clientes = new ClientesController();
const Diretores = new DiretoresController();
const modalDiretores = new ModalDiretoresController();
const contato = new ContatoController();