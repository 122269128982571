import OptionsModel from "../models/OptionsModel";
import SobreView from "../views/SobreView";

export default class MenuController {
    constructor() {
        this.btnMenu = document.querySelector('#open_menu');
        this.menu = document.querySelector('#menu-header');
        this.btnMenu.addEventListener('click', () => this.toggleMenu());

        // pagina sobre
        this.btnSobre = document.getElementById('sobreModal');
        this.btnSobre.addEventListener('click', this.modalSobre.bind(this));

        // menu target
        this.menuTarget = document.querySelectorAll('.menu-target');
        this.menuTarget.forEach(target => {
            target.addEventListener('click', this.animateMenu.bind(this));
        })
    }

    animateMenu(e) {
        e.preventDefault();

        if(window.innerWidth < 1170) {
            this.toggleMenu();
        }

        document.querySelector(e.target.hash).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    modalSobre(e) {
        e.preventDefault();
        const request = new OptionsModel();
        const sobreView = new SobreView(document.getElementById('modal'));
        this._toggleModal();
        request.getSobre(sobreView);
    }

    toggleMenu() {
        if (this.menu.classList.contains('visible')) {
            this.menu.classList.remove('visible');
            this.btnMenu.innerHTML = '<i class="fas fa-bars"></i>';
        } else {
            this.menu.classList.add('visible');
            this.btnMenu.innerHTML = '<i class="fas fa-times"></i>';
        }
    }

    _toggleModal() {
        const body = document.querySelector("body");
        if (body.classList.contains("modal-ative")) {
            body.classList.remove("modal-ative");
        } else {
            body.classList.add("modal-ative");
        }
    }
}
