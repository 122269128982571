import View from './View';

export default class CitacoesView extends View {
  _template(model) {
    const lang = localStorage.getItem('flag');
    return `
      <p class="citacoes">${lang === 'en' ? model.en : model.pt}</p>
    `;
  }
}
