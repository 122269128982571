import OptionModel from "../models/OptionsModel";
import ClientesView from "../views/ClientesView";

export default class ClientesController {
    constructor() {
        this.getClientes();
    }

    getClientes() {
        const request = new OptionModel();
        const clientesView = new ClientesView(document.getElementById('logos_clientes'));
        request.getClientes(clientesView);
    }
}